
@font-face {
  font-family: 'Monteserrat';
  src: url('fonts/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Monteserrat';
  src: url('fonts/Montserrat-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
 
}
body {
  margin: 0;
  font-family: Monteserrat -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
            padding: 10px 20px;
            background-color:  rgba( 70, 180, 3 ,0.5);
            color: black;
            border: none;
            cursor: pointer;
            font-size:18px;
            
}

.shadow{
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
}

.container {
  max-width: 95%;
  margin: auto;
 
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Monteserrat;
}
.header-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:10px;
  padding:15px;
}

.header-button-container{
  display: flex;
  gap:15px;
  align-items: center;
}
.header {
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
 
  height: 60px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
  -o-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
  -ms-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
}

#home{
  background:url("images/home.png");
  height: 20px;
  width: 20px;
  background-size: cover;

}

#nextButton{
  background:url("images/right-arrow.png");
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

#prevButton{
  background:url("images/right-arrow.png");
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

#logout{
  background:url("images/logout.png");
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

.login-content{
  align-items: center;
  width: 100%;
  height: 100vh;
}
.header-item{
  padding:10px;
  flex:1;
}
.item-field{
  flex:1;
}

.table{
  /*border: 1px solid #000;*/
  
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    font-size:22px;
    
    min-height: 600px;
    background:white;
    border-radius: 25px;
    display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.item-list{
  /*padding:20px;*/
}
.input-control{
  background : transparent;
  border : 1px solid #555;
  height: 32px;
  margin-top:10px;
}
.input-control,label{
  display: block;


}

.editBtn{
  background:url("images/editing.png");
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}
.pdfBtn{
  background:url("images/pdf-file.png");
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

.sendpdf{
  background:url("images/email.png");
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

.addDirectory{
  background:url("images/new-folder.png");
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

.deleteBtn{
  background:url("images/delete.png");
  height: 20px;
  width: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}

.addFile{
  background:url("images/add-file.png");
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}



.pagination{
  margin-top:30px;
}

.pagination .active{
  text-decoration: underline;
}
.form-group label{
  font-weight: bold;
}

#head-data{
  
}

.form-group{
  text-align: left;
}

#head-data label{
  font-size:17px;
  margin-top:15px;
}
.row {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  text-align: center;
  padding:18px;
  gap:10px;
}
.col{
  width:50%;
}

.col .label{
  font-size:16px;
  margin-bottom:10px;
}
.row:last-child {
  margin-bottom: 0;
}
.category, .label, .checkbox, .remarks {
  /*width: 25%;*/
}
.category {
  font-weight: bold;
}
.button-container {
  text-align: right;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.button {
  padding: 10px 20px;
  background-color:  rgba( 70, 180, 3 ,0.9);
  color: black;
  border: none;
  cursor: pointer;
  font-size:15px;
  text-transform: uppercase;
  font-weight: bold;
}
.button:disabled {
  background-color: #CCC;
}
@media (max-width: 600px) {
  .row {
      flex-direction: column;
      align-items: flex-start;
  }
  .category, .label, .checkbox, .remarks {
      width: 100%;
  }
}



/*body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f7f7f7;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}*/

.login-container {
  width: 300px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-align: center;
}

.login-container h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.login-container label {
  display: none;
}

.login-container input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.login-container input[type="text"], 
.login-container input[type="password"] {
  background-color: #f3f3f3;
}

.login-container button {
  width: 100%;
  padding: 12px 0;
  margin-top: 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #555;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.row .label{
  text-align: left;
}

.table .category{
  text-align: left;
}


/*pdf*/

.report-page {
 font-family: 'Roboto';
 font-weight: 400;
  padding: 20px;
}

.report-page .row{
  border-bottom:1px solid #ccc;
}

.report-page .col{
  text-align: left;
}
.report-header{
  text-align: center;
}
.report-header img{
  width: 100%;
}

table td{
  min-width:205px;
  min-height: 45px;
  padding-top:10px;
  vertical-align: top;
}
.report-header,
.report-body,
.report-summary {
  margin-bottom: 20px;
}

.report-info{
  padding-left:10px;
  margin-bottom:50px;
}

.report-summary{
  display: flex;
  flex-direction: column;
  
}

.formatted-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background: #fff;
}

.formatted-table th,
.formatted-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

.formatted-table th {
  
  font-weight: bold;
}
.formatted-table td:first-child {
  min-width:10px;
}
.formatted-table td[rowspan] {

  vertical-align: middle;
}



.main-category {
  border: 1px solid #ccc;
  margin-bottom: 15px;
  padding: 10px;
}

.category {
  background-color: rgba( 70, 180, 3 ,0.5);;
  vertical-align: middle;
  height: 25px;;
  border-radius:  25px 25px 0 0;
}

.formatted-table .category{
  background :#ccc;
}
.formatted-table .field {
  margin-bottom:8px;
}
.remarks-col{
  height: 50px;;

}
.checkbox-col{
  text-align: center !important;
}

.logo{
  background-image: url("images/pdf_logo.png");
  height: 100px;
  width: 300px;
  background-size: cover;
}

 /* Customize the label (the container) */
 .checkbox-container {
  display: inline;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
 
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} 

.label{
  display: flex;
  align-items: flex-end;
  gap : 5px;
}

.label input{
  border : none;
  border-bottom: 1px solid #000;
  
}

.documents-container{
  display: flex;
}


/*Modal*/

.modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  padding-top: 100px;
  overflow: auto;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  text-align: center;
}

/* Close Button */
.close-btn {
  color: #aaa;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
}

/* Action Button */
.action-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-btn {
  padding: 10px 20px;
  background-color: orangered;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left : 50px;
}

.action-btn:hover {
  background-color: #218838;
}

.document-table{
  justify-content: flex-start;
}

.document-table .title-field{
  text-align : left;
  padding-left : 15px;
}

#addFileModal label{
  cursor: pointer;
  display: inline-block;
  border: 0.5px black solid;
  padding:10px;
  margin-right: 10px;;
}

.document-table .item{
  padding : 2px;
  margin-bottom:unset;
}

.document-table .item-field{
  font-size:19px
}

nav ul li{

  list-style: none;
  display: inline-block;
  text-decoration: none;
  padding-right: 25px;
}

nav a{
  text-decoration: none;
  font-size:19px;
  color:black;
  
}

nav a.active{
  text-decoration: underline;
  font-weight: bold;
}

nav .documents {
  background-image : url("images/document.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding-left: 30px;
}

nav .reports {
  background-image : url("images/contract.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding-left: 30px;
}